<template>
  <section class="new-vehicle-view">
    <div class="new-vehicle-view__header p-4 py-2">
      <div style="display: flex; align-items: center">
        <RdxButton rect class="ci-action--back" @click="$__goBack">
          <RdxIcon class="ri-arrow-left-line rdx-icon--24" />
        </RdxButton>
        <CiHeading level="1" class="ml-2">{{ vehicleTitle }}</CiHeading>
        <div class="new-vehicle-view__actions">
          <RdxButton outline class="mr-2" data-cy="cancel" @click="$__goBack">
            {{ $t("global.cancel") }}
          </RdxButton>
          <RdxButton
            filled
            :disabled="pending.load || mediaIsUploading"
            :loading="pending.save"
            data-cy="submit"
            @click="updateVehicle"
          >
            {{ $t("global.save") }}
          </RdxButton>
        </div>
      </div>
    </div>

    <vehicle-navigation
      :active-anchor="activeAnchor"
      @scrolling-done="handleScroll"
    />
    <div ref="scrollable" class="new-vehicle-view__inner">
      <div class="pt-4 pb-5 pl-5 pr-5">
        <ValidationObserver ref="vehicle-form" tag="form">
          <!-- vehicle-details -->
          <vehicle-details
            ref="vehicle-form__details"
            class="pt-4"
            :pending="pending"
            :brand-options-list="options.brands"
            :nameplate-options-list="options.nameplates"
            :initial-brand="vehicle.brand"
            :initial-designer-choice="vehicle.designer_choice"
            :initial-nameplate="vehicle.nameplate"
            :initial-version="vehicle.version"
            :initial-drivetrain="vehicle.drivetrain"
            :initial-model-no="vehicle.model_no"
            :initial-description="vehicle.short_description"
            @vehicle:brand="$__setVehicle('brand', $event)"
            @vehicle:nameplate="$__setVehicle('nameplate', $event)"
            @vehicle:version="$__setVehicle('version', $event)"
            @vehicle:designer_choice="$__setVehicle('designer_choice', $event)"
            @vehicle:drivetrain="$__setVehicle('drivetrain', $event)"
            @vehicle:model_no="$__setVehicle('model_no', $event)"
            @vehicle:short_description="
              $__setVehicle('short_description', $event)
            "
          />

          <!-- availability -->
          <vehicle-availability
            ref="vehicle-form__availability"
            :pending="pending"
            :owner-options-list="options.companies"
            :initial-status="vehicle.status"
            :initial-delivery-date="vehicle.delivery_date"
            :initial-owner="vehicle.owner"
            :initial-branch-code="vehicle.branch_code.toString()"
            :price-hidden="vehicle.price_hidden"
            @vehicle:status="$__setVehicle('status', $event)"
            @vehicle:delivery_date="$__setVehicle('delivery_date', $event)"
            @vehicle:branch_code="$__setVehicle('branch_code', $event)"
            @vehicle:owner="$__setVehicle('owner', $event)"
            @hide-price="$__setVehicle('price_hidden', true)"
          />

          <!-- media -->
          <vehicle-media
            ref="vehicle-form__media"
            :pending="pending"
            :initial-photos="options.photos"
            :initial-video="vehicle.video_url"
            :initial-has360gallery="vehicle.has360gallery"
            @vehicle:photos="$__setVehicle('photos', $event)"
            @vehicle:video_url="$__setVehicle('video_url', $event)"
            @vehicle:has360gallery="$__setVehicle('has360gallery', $event)"
            @uploading="mediaIsUploading = $event"
          />

          <!-- specification -->
          <vehicle-specification
            ref="vehicle-form__specification"
            :pending="pending"
            :options="options"
            :color-options-list="options.colors"
            :selected-owner="vehicle.owner"
            :selected-brand="vehicle.brand"
            :initial-vehicle="vehicle"
            @vehicle:state="$__setVehicle('state', $event)"
            @vehicle:production_year="$__setVehicle('production_year', $event); $__setVehicle('model_year', $event)"
            @vehicle:vin="$__setVehicle('vin', $event)"
            @vehicle:color_code_ext="$__setVehicle('color_code_ext', $event)"
            @vehicle:color_name_ext="$__setVehicle('color_name_ext', $event)"
            @vehicle:color_code_int="$__setVehicle('color_code_int', $event)"
            @vehicle:color_name_int="$__setVehicle('color_name_int', $event)"
            @vehicle:equipment_no="$__setVehicle('equipment_no', $event); $__setVehicle('external_id', $event)"
            @vehicle:mileage="$__setVehicle('mileage', $event)"
            @vehicle:transmission="$__setVehicle('transmission', $event)"
            @vehicle:sale_state="$__setVehicle('sale_state', $event)"
            @vehicle:engine_type="$__setVehicle('engine_type', $event)"
            @vehicle:power="$__setVehicle('power', $event)"
            @vehicle:engine_capacity="$__setVehicle('engine_capacity', $event)"
            @vehicle:battery_capacity="
              $__setVehicle('battery_capacity', $event)
            "
            @vehicle:color="$__setVehicle('color', $event)"
            @vehicle:interior_color="$__setVehicle('interior_color', $event)"
          />

          <!-- price -->
          <vehicle-price
            ref="vehicle-form__price"
            :pending="pending"
            :initial-msrp-price="vehicle.msrp_price"
            :initial-monthly-price="vehicle.monthly_price"
            :initial-on-sale="vehicle.on_sale"
            :initial-sale-price="vehicle.sale_price"
            :initial-currency="vehicle.currency"
            :initial-price-hidden="vehicle.price_hidden"
            :owner-locale="vehicle.owner_locale"
            @vehicle:msrp_price="$__setVehicle('msrp_price', $event)"
            @vehicle:monthly_price="$__setVehicle('monthly_price', $event)"
            @vehicle:sale_price="$__setVehicle('sale_price', $event)"
            @vehicle:price_hidden="$__setVehicle('price_hidden', $event)"
            @vehicle:on_sale="$__setVehicle('on_sale', $event)"
          />

          <!-- equipment -->
          <vehicle-equipment
            ref="vehicle-form__equipment"
            :pending="pending"
            :feature-categories-options="options.featureCategories"
            :initial-vehicle="vehicle"
            @vehicle:long_description="
              $__setVehicle('long_description', $event)
            "
            @vehicle:approved_specs="
              $__setVehicle('approved_specs', $event)
            "
            @vehicle:features_standard="
              $__setVehicle('features_standard', $event)
            "
            @vehicle:features_optional="
              $__setVehicle('features_optional', $event)
            "
          />

          <!-- attachment -->
          <vehicle-attachment
            ref="vehicle-form__attachment"
            :pending="pending"
            :initial-attachment-url="vehicle.attachment_url"
            @attachment-loading="pending.load = true"
            @attachment-loaded="pending.load = false"
            @vehicle:attachment="$__setVehicle('attachment', $event)"
          />

          <!-- publication -->
          <vehicle-publication
            ref="vehicle-form__publication"
            :pending="pending"
            :listings-options="options.listings"
            :initial-listings="relationships.listings"
            @vehicle:listings="$__setVehicle('listings', $event)"
          />
        </ValidationObserver>
      </div>
    </div>
  </section>
</template>

<script>
import { serialise } from "kitsu-core";
import vehicleFormMixin from "./vehicle-form-mixin";
import goBackMixin from "./go-back-mixin";

import { RdxIcon, RdxButton } from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiHeading from "@/components/atoms/CiHeading";

import payloadRewriter from "@/utils/payload-rewriter";
import flatRelationships from "@/utils/flat-relationships";

export default {
  name: "VehicleEditView",

  components: {
    RdxIcon,
    RdxButton,
    CiHeading
  },

  mixins: [vehicleFormMixin, goBackMixin],

  created() {
    this.fetchVehicle();
  },

  methods: {
    fetchVehicle() {
      this.pending.load = true;
      this.$http
        .get("vehicles/" + this.ID + "?include=photos")
        .then(({ data }) => {
          payloadRewriter(this.vehicle, data.data.attributes);
          this.relationships = flatRelationships(data.data.relationships);
          this.options.photos = data.included;

          const {
            nameplate_brand_name: brand,
            nameplate_name: nameplate
          } = data.data.attributes;
          this.vehicleTitle = JSON.parse(
            JSON.stringify(`${brand} ${nameplate}`)
          );

          this.$__fetchOptions();
        })
        .finally(() => (this.pending.load = false))
        .catch(error => {
          console.error(error);
          this.$router.push({ name: "CarsInventoryStock" });
        });
    },

    updateVehicle() {
      this.$refs["vehicle-form"].validate().then(success => {
        if (!success) {
          this.$toast.error(
            this.$t("stock.shared-form.required-fields-are-missing")
          );
          return;
        }

        this.pending.save = true;
        const { vehicle, relationships } = this;
        const VEHICLE = serialise("vehicle", {
          ...vehicle,
          ...relationships
        });

        this.$http
          .patch("vehicles/" + this.ID, VEHICLE)
          .then(({ data }) => {
            const {
              nameplate_brand_name: brand,
              nameplate_name: nameplate
            } = data.data.attributes;

            this.$toast.success(
              this.$t("stock.vehicle-updated-successfull", {
                brand,
                nameplate
              })
            );

            this.$gtm.trackEvent({
              action: "edit",
              category: "vehicle",
              label: `${brand} ${nameplate}`,
              value: 1
            });
          })
          .finally(() => (this.pending.save = false));
      });
    }
  }
};
</script>

<style lang="scss">
.new-vehicle-view {
  &__inner {
    display: block;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 186px);
    overflow: auto;
    position: relative;
  }

  &__header {
    border-bottom: 1px solid $grey-5;
    padding: 17px 24px 21px;
  }

  &__grid {
    display: grid;
    grid-template-columns: 25% 75%;
    > div {
      padding: 0rem 2rem 0rem 0rem;
    }
  }
  &__actions {
    position: absolute;
    right: 1.5rem;
  }
  &__table {
    margin-bottom: 27px;
  }
}
</style>
